<template>
  <div class="wuYue-box">
    <HeadMobile carouseType="ABOUT_US"></HeadMobile>
    <div class="wuYue-body">
      <div class="title">
        {{ $store.state.staticName.AboutUs }}
      </div>
      <div class="gowuye-main">
        <!--        min-width: 126px;-->
        <div class="gowuye-main-nav" ref="navtag">
          <div
            :class="navIndex === '0' ? 'nav-active' : ''"
            @click="isActive('0')"
          >
            {{ $store.state.staticName.company_profiles }}
          </div>
          <div
            :class="navIndex === '1' ? 'nav-active' : ''"
            @click="isActive('1')"
          >
            {{ $store.state.staticName.glories }}
          </div>
          <!--          <div :style="{minWidth:$store.state.languageType!=='CHINESE'?'140px':''}" :class="navIndex==='2'?'nav-active':''" @click="isActive('2')">{{ $store.state.staticName.client }}</div>-->
          <div
            :class="navIndex === '3' ? 'nav-active' : ''"
            @click="isActive('3')"
          >
            {{ $store.state.staticName.company_photo }}
          </div>
          <div
            :class="navIndex === '4' ? 'nav-active' : ''"
            @click="isActive('4')"
          >
            {{ $store.state.staticName.video }}
          </div>
        </div>
        <!--                公司简绍-->
        <div class="body-box">
          <div class="gowuye-main-box" v-if="navIndex === '0'">
            <div class="itme">
              <div class="item-cent">
                <span class="gowuye-main-box-lable"
                  >{{ $store.state.staticName.company_name }}：</span
                >
                <span class="gowuye-main-box-value">{{
                  CPyProfile.cpyName
                }}</span>
              </div>
              <div class="xian"></div>
            </div>
            <div class="itme">
              <div class="item-cent">
                <span class="gowuye-main-box-lable"
                  >{{ $store.state.staticName.create_date }}：</span
                >
                <span class="gowuye-main-box-value">{{
                  CPyProfile.createDate
                }}</span>
              </div>
              <div class="xian"></div>
            </div>
            <div class="itme">
              <div class="item-cent">
                <span class="gowuye-main-box-lable"
                  >{{ $store.state.staticName.address }}：</span
                >
                <span class="gowuye-main-box-value">{{
                  CPyProfile.cpyAddress
                }}</span>
              </div>
              <div class="xian"></div>
            </div>
            <div class="itme">
              <div class="item-cent">
                <span class="gowuye-main-box-lable"
                  >{{ $store.state.staticName.legal_person }}：</span
                >
                <span class="gowuye-main-box-value">{{
                  CPyProfile.representative
                }}</span>
              </div>
              <div class="xian"></div>
            </div>
            <div class="itme">
              <div class="item-cent">
                <span class="gowuye-main-box-lable"
                  >{{ $store.state.staticName.office_clerk }}：</span
                >
                <span class="gowuye-main-box-value"
                  >{{ CPyProfile.people }}人</span
                >
              </div>
              <div class="xian"></div>
            </div>
            <div class="itme">
              <div class="item-cent">
                <span class="gowuye-main-box-lable"
                  >{{ $store.state.staticName.technical_support }}：</span
                >
                <span class="gowuye-main-box-value">{{
                  CPyProfile.technical
                }}</span>
              </div>
              <div class="xian"></div>
            </div>
            <div class="itme">
              <div class="item-cent">
                <span class="gowuye-main-box-lable"
                  >{{ $store.state.staticName.business_scope }}：</span
                >
                <span class="gowuye-main-box-value">{{
                  CPyProfile.scope
                }}</span>
              </div>
              <div class="xian"></div>
            </div>
            <div class="itme">
              <div class="item-cent">
                <span class="gowuye-main-box-lable"
                  >{{ $store.state.staticName.Company_certification }}：</span
                >
                <span class="gowuye-main-box-value">{{
                  CPyProfile.authentication
                }}</span>
              </div>
              <div class="xian"></div>
            </div>
            <div class="itme">
              <div class="item-cent">
                <span class="gowuye-main-box-lable"
                  >{{ $store.state.staticName.production_capacity }}：</span
                >
                <span class="gowuye-main-box-value">{{
                  CPyProfile.capacity
                }}</span>
              </div>
              <div class="xian"></div>
            </div>
            <div class="itme">
              <div class="item-cent">
                <span class="gowuye-main-box-lable"
                  >{{ $store.state.staticName.management_mode }}：</span
                >
                <span class="gowuye-main-box-value">{{
                  CPyProfile.management
                }}</span>
              </div>
              <div class="xian"></div>
            </div>
            <div class="itme">
              <div class="item-cent">
                <span class="gowuye-main-box-lable"
                  >{{ $store.state.staticName.domestic_client }}：</span
                >
                <span class="gowuye-main-box-value">{{
                  CPyProfile.domestic
                }}</span>
              </div>
              <div class="xian"></div>
            </div>
            <div class="itme">
              <div class="item-cent">
                <span class="gowuye-main-box-lable"
                  >{{ $store.state.staticName.overseas_customers }}：</span
                >
                <span class="gowuye-main-box-value">{{
                  CPyProfile.foreign
                }}</span>
              </div>
              <div class="xian"></div>
            </div>
            <div class="itme">
              <div class="item-cent">
                <span class="gowuye-main-box-lable">URL：</span>
                <span class="gowuye-main-box-value">{{ CPyProfile.url }}</span>
              </div>
              <div class="xian"></div>
            </div>
          </div>
          <!--      公司荣誉-->
          <div class="gowuye-main-box" v-if="navIndex === '1'">
            <div class="rongyu">
              <div
                class="rongyu-img"
                v-for="(item, i) in rongYuList"
                :key="'img' + i"
              >
                <el-image
                  style="width: 100%; height: 100%; border-radius: 5px"
                  :src="item.abbreviation[0]"
                  :preview-src-list="item.abbreviation"
                >
                </el-image>
              </div>
            </div>
            <new-pagination
              @current-change="handleCurrentChange"
              :page-size="page.pageSize"
              layout="prev, pager, next"
              :total="total"
            ></new-pagination>
          </div>
          <!--      合作客户-->
          <!--          <div class="gowuye-main-box" v-if="navIndex==='2'">-->
          <!--            <div class="rongyu">-->
          <!--&lt;!&ndash;              <div class="rongyu-img" v-for="(item,i) in rongYuList" :key="'img'+i">&ndash;&gt;-->
          <!--&lt;!&ndash;                <el-image&ndash;&gt;-->
          <!--&lt;!&ndash;                    style="width: 100%; height: 100%"&ndash;&gt;-->
          <!--&lt;!&ndash;                    :src="item"&ndash;&gt;-->
          <!--&lt;!&ndash;                    :preview-src-list="rongYuList">&ndash;&gt;-->
          <!--&lt;!&ndash;                </el-image>&ndash;&gt;-->
          <!--&lt;!&ndash;              </div>&ndash;&gt;-->
          <!--            </div>-->
          <!--          </div>-->
          <!--      公司相册-->
          <div class="gowuye-main-box" v-if="navIndex === '3'">
            <div v-if="displayFlg === 'list'">
              <div class="rongyu">
                <div
                  class="rongyu-img"
                  v-for="(item, i) in CPYAlbum"
                  :key="'img' + i"
                >
                  <el-image
                    style="width: 100%; height: 100%; border-radius: 5px"
                    :src="item.abbreviations[0]"
                    :preview-src-list="item.abbreviations"
                  >
                  </el-image>
                  <div class="customer-title" @click="goDetail(item.id)">
                    {{ item.title }}
                  </div>
                </div>
              </div>
              <new-pagination
                @current-change="handleCurrentChange1"
                :page-size="page1.pageSize"
                layout="prev, pager, next"
                :total="total1"
              ></new-pagination>
            </div>
            <div v-if="displayFlg === 'detail'">
              <div class="detail-title">
                {{ detail.title }}
              </div>
              <div class="detail-context">
                {{ detail.description }}
              </div>
              <div style="text-align: center">
                <el-image
                  style="width: 80%; border-radius: 8rem"
                  :src="detail.abbreviation[0]"
                  :preview-src-list="detail.abbreviation"
                ></el-image>
              </div>
            </div>
          </div>
          <!--      视屏中心-->
          <div class="gowuye-main-box" v-if="navIndex === '4'">
            <div class="rongyu">
              <div
                class="rongyu-img"
                style="border-radius: 5px"
                v-for="(item, i) in CPYVideo"
                :key="'img' + i"
              >
                <video
                  @click="openVideo(item.video[0])"
                  class="video"
                  :src="item.video[0]"
                  v-if="validFileType(item.video[0])"
                ></video>
                <el-image
                  :preview-src-list="[item.video]"
                  class="suo-img"
                  :src="item.video[0]"
                  v-else
                ></el-image>
              </div>
            </div>
            <new-pagination
              @current-change="handleCurrentChange2"
              :page-size="page2.pageSize"
              layout="prev, pager, next"
              :total="total2"
            ></new-pagination>
          </div>
        </div>
      </div>
    </div>
    <!--    视屏播放弹框-->
    <el-dialog
      :visible.sync="dialogVideo"
      class="myvideo"
      :before-close="handleClose"
    >
      <video autoplay class="myvideo1" :src="video"></video>
    </el-dialog>
    <Foot1Mobile></Foot1Mobile>
  </div>
</template>

<script>
import HeadMobile from "@/components/mobile/HeadMobile";
import Foot1Mobile from "@/components/mobile/FootMobile";
import { mapState } from "vuex";

export default {
  name: "GoWuYueMobile",
  components: { Foot1Mobile, HeadMobile },
  data() {
    return {
      navIndex: "0",
      dialogVideo: false,
      CPyProfile: {},
      total: 0,
      total1: 0,
      total2: 0,
      page: {
        currentPage: 1,
        pageSize: 12,
        input: "",
      },
      page1: {
        currentPage: 1,
        pageSize: 12,
        input: "",
      },
      page2: {
        currentPage: 1,
        pageSize: 12,
        input: "",
      },
      activeShow: "0",
      displayFlg: "list",
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      rongYuList: [],
      CPYAlbum: [],
      CPYVideo: [],
      video: "",
    };
  },
  created() {
    // this.getCPYProfiles();
    this.isActive("0");
  },
  watch: {
    "$store.state.languageType": {
      handler(newVal) {
        this.getCPYProfiles();
        // this.getCPYHonor();
        this.getCPYAlbum();
        // this.getCPYVideo();
      },
      // deep: true // 深度监听
    },
  },
  computed: {
    ...mapState({
      languageType: (state) => state.languageType, // 语言类型
    }),
  },
  methods: {
    openVideo(video) {
      this.dialogVideo = true;
      this.video = video;
    },
    handleClose(done) {
      done();
      this.dialogVideo = false;
      this.video = "";
    },
    // 标签切换
    isActive(m) {
      this.displayFlg = "list";
      if (m === "1") {
        this.getCPYHonor();
      } else if (m === "3") {
        this.getCPYAlbum();
      } else if (m === "4") {
        this.getCPYVideo();
      } else if (m === "0") {
        this.getCPYProfiles();
      }
      this.navIndex = m;
    },
    //获取公司简介
    // /queryCPY_ProfilesByLanguage
    getCPYProfiles() {
      this.$axios({
        url: "/queryCPY_ProfilesByLanguage",
        method: "GET",
        params: {
          type: this.languageType,
        },
      }).then((res) => {
        this.CPyProfile = res.data.value ? res.data.value : {};
      });
    },
    //获取公司相册
    getCPYAlbum() {
      this.page1.language = this.languageType;
      this.$axios({
        url: "/searchCooperativeCustomers",
        method: "GET",
        params: this.page1,
      }).then((res) => {
        this.CPYAlbum = res.data.value.value;
        this.total1 = res.data.value.total;
      });
    },

    //公司荣誉数据
    getCPYHonor() {
      this.$axios({
        url: "/queryCPY_Honor",
        method: "GET",
        params: this.page,
      }).then((res) => {
        this.rongYuList = res.data.value.value;
        this.total = res.data.value.total;
      });
    },
    //视屏中心数据
    getCPYVideo() {
      this.$axios({
        url: "/queryCPY_Video",
        method: "GET",
        params: this.page2,
      }).then((res) => {
        this.CPYVideo = res.data.value.value;
        this.total2 = res.data.value.total;
      });
    },

    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.page.currentPage = val;
      this.getCPYHonor();
    },
    handleCurrentChange1(val) {
      this.page1.currentPage = val;
      this.getCPYAlbum();
    },
    handleCurrentChange2(val) {
      this.page2.currentPage = val;
      this.getCPYVideo();
    },
    validFileType(fileName) {
      const index = fileName.lastIndexOf(".");
      const ext = fileName.substring(index + 1);
      if (ext == "mp4") return true;
      return false;
    },
    goDetail(id) {
      this.$axios({
        url: "/getCooperativeCustomersDetail",
        method: "GET",
        params: {
          id: id,
          language: this.languageType,
        },
      }).then((res) => {
        this.detail = res.data.value;
        this.displayFlg = "detail";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-pagination) {
  margin-top: 10px;
}
.wuYue-box {
  .wuYue-body {
    .title {
      //height: 15px;
      margin-top: 14px;
      margin-bottom: 8px;
      text-align: center;
      width: 375px;
      font-size: 14px;
      font-weight: 500;
      color: #4d4d4d;
    }

    .customer-title {
      background-color: rgb(171 171 171 / 46%);
      position: relative;
      top: -18px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-left: 3rem;
      color: #4d4d4d;
    }
    .gowuye-main {
      .gowuye-main-nav {
        margin: {
          top: 5px;
          left: 10px;
        }
        padding: 0 5px;
        overflow-x: scroll;
        width: 350px;
        height: 24px;
        display: flex;
        background: #ffffff;
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
        border-radius: 2px;

        div {
          flex: 1;
          height: 20px;

          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #333333;
        }

        .nav-active {
          border-bottom: 1px solid #1850af;
          color: #1850af;
        }
      }
      .body-box {
        min-height: 200px;
      }
      .gowuye-main-box {
        margin: {
          top: 22px;
          left: 10px;
          bottom: 40px;
        }

        .itme {
          min-height: 26px;
          padding-bottom: 5px;

          .item-cent {
            width: 353px;
            min-height: 18px;
            margin-bottom: 5px;
            display: flex;
            border-bottom: 1px solid #808080;

            .gowuye-main-box-lable {
              white-space: nowrap;
              font-size: 12px;
              font-weight: 700;
              text-align: left;
              color: #333333;
            }

            .gowuye-main-box-value {
              //padding-bottom: 5px;
              display: inline-block;
              word-break: break-all;
              display: -webkit-box; /**对象作为伸缩盒子模型展示**/
              -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
              width: 300px;
              font-size: 12px;
              font-weight: 500;
              text-align: left;
              color: #4d4d4d;
              padding-top: 2px;
            }
          }

          /*.xian {
            margin-top: 2px;
            width: 350px;
            height: 1px;
            background: #808080;
          }*/
        }
        .rongyu {
          display: grid;
          grid-template-columns: 119px 119px 119px;
          grid-template-rows: 80px 80px 80px;
          grid-row-gap: 10px;

          .rongyu-img {
            width: 113px;
            height: 78px;
            background: #f1f1f1;
          }
        }
      }
    }
  }
  .video {
    object-fit: fill;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  .detail-title {
    text-align: center;
    font-size: 14rem;
    margin-bottom: 5px;
  }
  .detail-context {
    font-size: 13rem;
    margin: 15px 10px;
  }
}
:deep(.el-dialog__body),
:deep(.el-dialog__header) {
  padding: 0 !important;
}
.myvideo1 {
  width: 100%;
  height: 100%;
  margin: 0 -8px -5px 0;
}
:deep(.el-dialog__headerbtn) {
  border: 1px solid #ffffff;

  border-radius: 30px;
  z-index: 9999;
}
:deep(.el-dialog__headerbtn .el-dialog__close) {
  color: #ffffff;
}

/* :deep( .el-carousel__container), my-carousel {
  height: 120px !important;
}*/
</style>
